// Основные переменные
$primary-color: #fa913c;
$secondary-color: #78bf38;
$border-color: #ececec;
$background-light: #f9f9f9;
$input-focus-bg: #fff;
$box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
$text-dark: #333;
$text-light: #555;
$error-color: #f57676;
$input-bg: #f5f5f5;



// Макет для мобильных устройств
@media (max-width: 768px) {
  .orderBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-top: 1px solid $border-color;
    gap: 40px;

    // Секция информации
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      width: 100%;
      max-width: 500px;
      padding: 20px;
      background-color: $background-light;
      border-radius: 10px;
      box-shadow: $box-shadow;

      // Заголовок
      .title {
        font-size: 28px;
        font-weight: 600;
        color: $text-dark;
        text-align: center;
      }

      // Время доставки
      .delivery {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;

        .timeTitle {
          font-size: 18px;
          font-weight: 600;
          color: $text-dark;
        }

        .time {
          width: auto;
          text-align: left;
          background: $input-bg;
          color: $text-light;
          padding: 10px;
          border: 1px solid $border-color;
          border-radius: 7px;
          font-size: 16px;
        }
      }

      // Ошибка
      .errorMessage {
        color: $error-color;
        text-align: center;

        a {
          color: $primary-color;
          text-decoration: none;
          transition: color 0.3s;

          &:hover {
            color: darken($primary-color, 10%);
          }
        }
      }

      // Контакты пользователя
      .contacts {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        padding: 15px;
        background-color: $input-focus-bg;
        border-radius: 10px;
        box-shadow: $box-shadow;

        .name {
          font-size: 20px;
          font-weight: 600;
          color: $text-dark;
        }

        .email {
          font-size: 16px;
          color: $text-dark;
        }
      }

      // Профиль клиента
      .profile {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;

        label {
          font-size: 18px;
          color: $text-light;
        }

        select {
          width: 100%;
          padding: 12px;
          border: 1px solid $border-color;
          border-radius: 5px;
          background-color: $input-bg;
          font-size: 16px;
          color: $text-dark;
          transition: border-color 0.3s;

          &:focus {
            border-color: $primary-color;
          }
        }
      }

      // Адрес
      .adress {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .adressTitle {
          font-size: 18px;
          font-weight: 600;
          color: $text-dark;
        }

        .adressLine {
          padding: 12px;
          background-color: $input-bg;
          border: 1px solid $border-color;
          border-radius: 5px;
          font-size: 16px;
          color: $text-dark;
        }
      }

      // Способ оплаты
      .payment {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        img {
          width: 24px;
          height: 24px;
        }

        label {
          font-size: 18px;
          color: $text-light;
        }

        select {
          width: 100%;
          padding: 12px;
          border: 1px solid $border-color;
          border-radius: 5px;
          background-color: $input-bg;
          font-size: 16px;
          color: $text-dark;
          transition: border-color 0.3s;

          &:focus {
            border-color: $primary-color;
          }
        }
      }

      // Итоговая сумма и кнопка оплаты
      .finish {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        background-color: $background-light;
        border: 1px solid $border-color;
        border-radius: 10px;
        box-shadow: $box-shadow;

        .money {
          font-size: 24px;
          font-weight: bold;
          color: $secondary-color; /* Зелёный цвет для суммы */
        }

        .pay {
          padding: 12px 20px; /* Увеличенный padding для кнопки */
          background-color: $secondary-color; /* Светло-зелёный цвет для кнопки оплаты */
          color: #fff;
          border: none;
          border-radius: 5px;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
          transition: background-color 0.3s;

          &:hover {
            background-color: darken($secondary-color, 10%); /* Тёмно-зелёный при наведении */
          }
        }
      }
    }

    // Список товаров
    .list {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      max-width: 500px;
      margin: 0 auto;

      .item {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        background-color: $input-focus-bg;
        border: 1px solid $border-color;
        border-radius: 10px;
        box-shadow: $box-shadow;

        .imageBox {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $background-light;
          border-radius: 10px;
          padding: 15px;

          img {
            width: 100px;
            height: 100px;
          }
        }

        .infoBox {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .title {
            h1 {
              font-size: 20px;
              color: $text-dark;
            }
          }

          .priceBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            color: $text-dark;

            .price {
              font-weight: bold;
            }

            .action {
              font-size: 20px;
              color: $secondary-color;
              font-weight: 600;
            }
          }

          // Управление количеством
          .amounts {
            display: flex;
            justify-content: center;
            gap: 15px;

            button {
              width: 40px;
              height: 40px;
              background-color: $input-bg;
              border: 1px solid $border-color;
              border-radius: 5px;
              font-size: 18px;
              color: $text-dark;
              cursor: pointer;
              transition: background-color 0.3s;

              &:hover {
                background-color: $background-light;
              }
            }

            .amount {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40px;
              height: 40px;
              background-color: $input-bg;
              border: 1px solid $border-color;
              border-radius: 5px;
            }
          }

          .buttons {
            display: flex;
            justify-content: center;
            gap: 15px;

            .delete {
              padding: 10px;
              background-color: $error-color;
              color: white;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              transition: background-color 0.3s;

              &:hover {
                background-color: darken($error-color, 10%);
              }
            }
          }
        }
      }
    }
  }
}
