.history {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 90px;
    .mainTitle {
        color: #1A1A1A;
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .contentHistory{
        border: 1px solid rgb(175, 175, 175);
        border-radius: 20px;
        box-shadow: 0px 0px 5px 3px rgb(175, 175, 175);
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }
    .historyList{
        margin-top: 10px;
    }
    .infoBox{
        .info{
            .client{
                margin-top: 5px;
                color: #1A1A1A;
                text-align: start;
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            .historyBox{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .products{
                    margin-bottom: 20px;
                    .imageHistory{
                        width: 100px;
                        height: 100px;
                        border: 1px solid;
                        border-radius: 20px;
                        margin-right: 20px;
                        object-fit: contain;
                    }
                }
            }
        }
    }

    .delivery{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 30px;
        .timeTitle{
            font-size: 17px;
            color: #797878;
        }
        .timeContent{
            font-size: 14px;
            color: #797878;

        }
        .total{
            .totalTitle{
                color: #504F4F;
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: center;
            }
            .totalText{
                color: #15BA15;
                text-align: center;
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
}
