.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px 0 80px;

  .order_success_header {
    padding: 0 15px;
  }

  .success_content {
    display: flex;
    justify-content: center;
    .content_block {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;
      justify-content: center;
      max-width: 700px;

      .success_icon {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 50%;
          top: 0;
          margin: 0 0 0 -35px;
          width: 70px;
          height: 70px;
          background: url('../../../../public/success-icon.svg') 0 0 no-repeat;
          background-color: #78bf38;
        }

      }
      .content_info {
        text-align: center;
      }
    }

    .success_footer {
      border: 1px solid #ececec;
      padding: 30px 30px 60px;
      border-radius: 3px;
      width: 100%;
      display: flex;
      gap: 5px;

      .card_block {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .info {
          display: flex;
          align-items: center;
          gap: 20px;
          .img_block {
            border: 1px solid #ececec;
            width: 118px;
            height: 70px;
            line-height: 68px;
            text-align: center;
            border-radius: 3px;

            img {
              width: 100px;
            }
          }

          p {
            margin: 0;
          }
        }
      }
    }
  }
}
