/* Define the height of the navigation bar */
:root {
  --navbar-height: 70px; /* Adjust this value based on the actual height of the navigation bar */
}

.catalog-page {
  padding: 20px;
  margin-bottom: var(--navbar-height); /* Ensure the catalog content doesn't overlap with the navbar */
}

.catalog-title {
  font-size: 36px;
  margin-bottom: 20px;
}

.breadcrumb {
  font-size: 14px;
  color: #999;
  margin-bottom: 30px;
}

.catalog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.catalog-item {
  background: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.catalog-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.catalog-item .parent-link {
  text-decoration: none;
  color: #333;
}

.catalog-item .parent-link .parent-title {
  font-size: 18px;
  font-weight: bold;
  display: block;
  cursor: pointer;
}

.catalog-item .subcategories {
  margin-top: 10px;
}

.catalog-item .subcategories .child-link {
  text-decoration: none;
  color: #666;
}

.catalog-item .subcategories .child-link .child-title {
  font-size: 14px;
  display: block;
  margin: 5px 0;
}

/* Navigation bar styles */
@media (max-width: 768px) {
  .navigation--list {
    background-color: #fff;
    border-top: 1px solid #ccc;
    padding: 10px 15px;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 10002;
  }

  .navigation--list .navigation__content {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 400px;
  }

  .navigation--list .navigation__item {
    text-align: center;
    width: 20%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navigation--list .navigation__item img {
    width: 22px;
  }

  .navigation--list .navigation__item span {
    font-size: 13px;
    color: #504F4F;
    margin-top: 3px;
  }

  .navigation--list .navigation__item .orderSize {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    top: -8px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
}
