.product {
    display: flex;
    padding-top: 60px;
    gap: 50px;
    flex-direction: column;

    .main_info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .productImg {
            display: flex;
            justify-content: center;
            width: 60%;
            padding: 27px 39px 39px;
            border: 1px solid #ececec;
            align-items: center;

            img {
                width: 400px;
                height: 400px;
                border: none;
            }
        }

        .block-inform {
            width: 35%;
            h3 {
                margin-bottom: 40px;
            }

            .textBox {
                margin-bottom: 40px;
            }

            .buyBox {
                display: flex;
                justify-content: center;
                gap: 10px;
                align-items: center;
                flex-direction: row;
                flex-wrap: wrap;

                .priceBuyBox {
                    display: flex;
                    gap: 10px;

                    .actionPrice {
                        color: #15ba15;
                        text-align: center;
                        font-size: 28px;
                    }

                    .actualPrice {
                        color: #8c8c8c;
                        text-align: center;
                        font-size: 18px;
                        text-decoration-line: line-through;
                    }
                }

                .quantityBox {
                    button {
                        border-radius: 50%;
                        width: 45px;
                        height: 45px;
                        font-size: 18px;
                        font-weight: bold;
                        border: none;
                        cursor: pointer;
                    }

                    input {
                        width: 30px;
                        min-width: 20px;
                        height: 24px;
                        border: none;
                        text-align: center;
                    }
                }

                .btnBucketProd {
                    width: 150px;
                    height: 50px;
                    border: none;
                    border-radius: 4px;
                    background-color: #93cc60; /* Светло-зелёный цвет по умолчанию */
                    color: white;
                    text-align: center;
                    font-size: 14px;
                    text-transform: uppercase;
                    transition: all 0.3s;

                    &:hover {
                        background-color: #78bf38; /* Цвет при наведении */
                    }

                    &.in-cart {
                        background-color: #589521; /* Тёмно-зелёный цвет, когда товар в корзине */
                        color: white;
                    }
                }
            }
        }
    }

    .block-inform__text {
        margin-bottom: 90px;

        .tabs {
            margin-bottom: 40px;
            width: 260px;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    padding: 16px 24px 15px;
                    border-radius: 3px;
                    border: 1px solid #ececec;
                    font-size: 15px;
                    position: relative;

                    &:after {
                        content: "";
                        position: absolute;
                        left: -1px;
                        right: -1px;
                        bottom: -1px;
                        z-index: 1;
                        height: 2px;
                        background: #fa9441;
                        border-bottom-left-radius: 3px;
                        border-bottom-right-radius: 3px;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .main_info {
            flex-direction: column;

            .productImg {
                width: 100%;

                img {
                    width: auto;
                    height: auto;
                    min-height: 200px;
                }
            }

            .block-inform {
                width: 100%;
            }
        }
    }
}
