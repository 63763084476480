.goods{
    .title{
        color: #1A1A1A;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .item{
        padding-top: 5%;
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(4, 1fr);

        .card{
            .card-body{
                .card-title{
                    color: #504F4F;
                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-align: center;
                }

                .card-text{
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                    .priceBox{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        .price{
                            color: #8C8C8C;
                            text-align: center;
                            font-family: Montserrat;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            text-decoration-line: line-through;
                        }
                        .action{
                            color: #15BA15;
                            text-align: center;
                            font-family: Montserrat;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }
                    }
                    .btnBucket{
                        border: none;
                        border-radius: 10px;
                        background-color: #FA9441;
                        color: white;
                        text-align: center;
                        font-size: 14px;

                        img{
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
        }
    }
}