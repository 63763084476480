.formContainerRegistr {
    background-position: center center;
    display: flex;
    padding-top: 4%;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    border-top: 1px solid #f5f5f5;

    .formWrapperRegistr {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        width: 100%; /* Используем 100% ширины контейнера */
        max-width: 440px; /* Ограничиваем максимальную ширину */
        padding: 0 20px; /* Добавляем симметричные отступы по бокам */

        @media (max-width: 768px) {
            width: 90%; /* Для мобильных устройств */
            padding-left: 5%; /* Добавляем отступ слева для мобильных */
            padding-right: 5%; /* Добавляем отступ справа для симметрии */
        }

        .titleRegist {
            font-size: 28px;
        }

        .errorMessage {
            color: #FA913C;
        }

        .formRegistr {
            display: flex;
            flex-direction: column;
            align-items: center; /* Центрируем форму */
            gap: 25px;
            width: 100%; /* Форма занимает 100% ширины родительского контейнера */
  
            .name,
            .phone,
            .email,
            .password {
                display: flex;
                align-items: center;
                position: relative;
                width: 100%; /* Поля ввода занимают 100% ширины формы */
                
                .eyeIcon {
                    cursor: pointer;
                }     

                input {
                    width: 100%; /* Ширина 100% для мобильной адаптации */
                    padding: 15px 15px 15px 50px;
                    align-items: center;
                    background: #f8f8f8;
                    border: 1px solid #ececec;
                    border-radius: 3px;
                    outline: none;
                    transition: all 0.1s linear;

                    &::placeholder {
                        color: #777777;
                        font-size: 15px;
                        line-height: normal;
                        padding-left: 2px;
                    }

                    &:focus {
                        background: #fff;
                    }
                }

                img {
                    width: 26px;
                    height: 26px;
                    position: absolute;
                    top: 14px;
                    left: 14px;
                    opacity: 0.5;
                }

                .eyeIcon {
                    cursor: pointer;
                    right: 13px;
                    left: auto;
                }
            }

            .buttons {
                width: 100%;
                margin-bottom: 40px;
                display: flex;
                justify-content: center; /* Центрируем кнопки */

                button {
                    width: 140px;
                    padding: 13px;
                    border-radius: 3px;
                    background: #FA913C;
                    border: 1px solid #FA913C;
                    color: #FFF;
                    text-align: center;
                    font-size: 15px;
                    transition: all 0.2s linear;

                    &:hover {
                        background: #f77c18;
                    }
                }

                a {
                    padding: 11px;
                    color: #FA913C;
                    border: 1px solid #FA913C;
                    border-radius: 3px;
                    font-size: 15px;
                    transition: all 0.2s linear;

                    &:hover {
                        background: #FA913C;
                        color: #FFF;
                    }
                }

                span {
                    color: rgba(26, 26, 26, 0.50);
                    font-size: 16px;

                    .link {
                        color: #FA913C;
                        text-decoration: none; 
                    }
                }
            }

            .passwordErrorBlock,
            .errorMessage {
                width: 80%;
                margin: 0 auto;
                text-align: center;
            }

            .checkboxContainer {
                display: flex;
                align-items: center;
                gap: 10px; /* Расстояние между чекбоксом и текстом */
                flex-wrap: nowrap; /* Запрещаем перенос элементов на новую строку */
                flex-shrink: 0; /* Предотвращаем сжатие контейнера */
                white-space: nowrap; /* Предотвращаем перенос текста на новую строку */
                
                .infoRegistrSpan {
                    font-size: 14px; /* Размер шрифта */
                    font-weight: 600;
                    white-space: nowrap; /* Запрещаем перенос текста */
                    overflow: hidden; /* Если текст слишком длинный, скрываем его часть */
                    text-overflow: ellipsis; /* Добавляем многоточие, если текст обрезается */
                }
            
                input[type="checkbox"] {
                    width: 18px; /* Размер чекбокса */
                    height: 18px;
                    flex-shrink: 0; /* Предотвращаем сжатие чекбокса */
                }
            }
            
            @media (max-width: 768px) {
                .checkboxContainer {
                    gap: 5px; /* Уменьшаем расстояние между чекбоксом и текстом для мобильных устройств */
            
                    .infoRegistrSpan {
                        font-size: 12px; /* Уменьшаем размер шрифта для мобильных устройств */
                    }
            
                    input[type="checkbox"] {
                        width: 16px; /* Уменьшаем размер чекбокса для мобильных */
                        height: 16px;
                    }
                }
            }
            
                     
            

            .registrationBtn {
                width: 100%; /* Центрируем и растягиваем кнопку на 100% */
                max-width: 300px; /* Ограничиваем максимальную ширину кнопки */
                margin: 0 auto;
                padding: 13px;
                border-radius: 3px;
                background: #FA913C;
                border: 1px solid #FA913C;
                color: #FFF;
                text-align: center;
                font-size: 16px;
                transition: all 0.2s linear;

                &:hover {
                    background: #f77c18;
                }
            }
        }

        .infoRegistrSpan {
            width: 100%; /* Центрируем текст */
            max-width: 300px; /* Ограничиваем ширину */
            margin: 0 auto;
            text-align: center;

            .link {
                color: #FA913C;
                padding-left: 5px;
                text-decoration: none;            
            }
        }
    }
}
