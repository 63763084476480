@media (max-width: 768px) {
  .cart {
    padding: 20px 10px;
    background-color: #f8f8f8; /* Светло-серый фон для корзины */

    .titlePage {
      margin-bottom: 20px;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      color: #333;
    }

    .basket {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 40px;
    }

    .mainBox {
      width: 100%;
      padding: 0 5%;

      .titlePage {
        margin: 10px 0;
        font-size: 20px;
        text-align: center;
        color: #555;
      }
    }

    .carts {
      display: flex;
      flex-direction: column;
      background: #fff;
      border: 1px solid #ececec;
      border-radius: 10px;
      padding: 20px;
      margin-bottom: 20px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

      .imageBox {
        text-align: center;
        margin-bottom: 15px;
        img {
          width: 100px;
          border-radius: 8px;
        }
      }

      .title {
        width: 100%;
        h3 {
          font-size: 18px;
          padding-top: 5px;
          text-align: center;
          color: #222;
        }
      }

      .priceBox {
        padding-top: 10px;
        text-align: center;
        color: #777;

        .price {
          font-size: 14px;
          text-decoration-line: line-through;
          color: #8c8c8c;
        }

        .action {
          font-size: 16px;
          font-weight: 700;
          color: #78bf38; /* Основной зеленый цвет для акционных цен */
        }
      }

      .amounts {
        display: flex;
        justify-content: center;
        margin: 10px 0;

        button {
          transition: all 0.2s linear;
          cursor: pointer;
          &:hover {
            color: #78bf38; /* Зеленый цвет при наведении */
          }
        }

        .btnPlus,
        .btnMinus {
          width: 35px;
          height: 35px;
          font-size: 16px;
          border: 1px solid #eaeaea;
          background: #f8f8f8; /* Светлый фон для кнопок */
          border-radius: 5px;
        }

        .amount {
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #eaeaea;
          margin: 0 5px;
        }
      }

      .buttons {
        display: flex;
        justify-content: center;
        gap: 10px;

        .delete {
          padding: 10px;
          font-size: 13px;
          border: none;
          cursor: pointer;
          background: #ff6b6b;
          color: white;
          border-radius: 5px;
          transition: background 0.3s;
          &:hover {
            background: #ff4c4c;
          }
        }
      }
    }
  }

  .checkout {
    width: 100%;
    padding: 20px;
    border: 1px solid #ececec;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    .titleSum {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 700;
      color: #333;
    }

    .buttonBox {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .buy,
      .clear {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        height: 45px;
        font-size: 14px;
        border: none;
        cursor: pointer;
        color: white;
        text-decoration: none;
        border-radius: 5px;
        transition: background 0.3s;
      }

      .buy {
        background: #93cc60; /* Светло-зеленый цвет для кнопки покупки */
        &:hover {
          background: #78bf38; /* Зеленый цвет при наведении */
        }
      }

      .clear {
        background: #f57676; /* Красный цвет для кнопки очистки корзины */
        &:hover {
          background: #e74c3c; /* Темный красный цвет при наведении */
        }
      }
    }
  }
}
