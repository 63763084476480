body {
    padding-bottom: 80px; // Ensures content is above the navbar
}
.nav-list {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #333;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        li {
            margin: 0 15px;
            a {
                color: #fff;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
