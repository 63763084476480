.create{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin-inline:auto;
    width:400px;
    margin-left: 50%;

    .errorText{
        color: red;
        margin-bottom: 20px;
        margin-left: 20%;
    }

    .infoText{
        color: #02C66F;
        margin-bottom: 20px;
        margin-left: 20%;
    }

    .switchLegal{
        display: flex;
        min-height: 100%;
        justify-content: space-between;
        align-items: center;
        font-family: sans-serif;
        margin-bottom: 20px;
        margin-top: 10px;

        .tgl {
            display: none;
            &,
            &:after,
            &:before,
            & *,
            & *:after,
            & *:before,
            & + .tgl-btn {
              box-sizing: border-box;
              &::selection {
                background: none;
              }
            }
            
            + .tgl-btn {
              outline: 0;
              display: block;
              width: 4em;
              height: 2em;
              position: relative;
              cursor: pointer;
              user-select: none;
              &:after,
              &:before {
                position: relative;
                display: block;
                content: "";
                width: 50%;
                height: 100%;
              }
              
              &:after {
                left: 0;
              }
              
              &:before {
                display: none;
              }
            }
            
            &:checked + .tgl-btn:after {
              left: 50%;
            }
          }

        .tgl-flip {
            + .tgl-btn {
              padding: 2px;
              transition: all .2s ease;
              font-family: sans-serif;
              perspective: 100px;
              &:after,
              &:before {
                display: inline-block;
                transition: all .4s ease;
                width: 100%;
                text-align: center;
                position: absolute;
                line-height: 2em;
                font-weight: bold;
                color: #fff;
                position: absolute;
                top: 0;
                left: 0;
                backface-visibility: hidden;
                border-radius: 4px;
              }
              
              &:after {
                content: attr(data-tg-on);
                background: #02C66F;
                transform: rotateY(-180deg);
              }
              
              &:before {
                background: #FF3A19;
                content: attr(data-tg-off);
              }
          
              &:active:before {
                transform: rotateY(-20deg);
              }
            }
            
            &:checked + .tgl-btn {
              &:before {
                transform: rotateY(180deg);
              }
              
              &:after {
                transform: rotateY(0);
                left: 0;
                background: #7FC6A6;
              }
              
              &:active:after {
                transform: rotateY(20deg);
              }
            }
    }
}

    .title{
        color: #1A1A1A;
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 20px;
    }

    img {
        width: 30px;
        height: 30px;
        flex-shrink: 0; 
    }

    label{
        font-weight:bold;
    }

    input{
        width:400px;
        margin-bottom: 10px;
        display: flex;
        padding: 20px 15px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 7px;
        border:none;
        background: #FFF;
        border: 1px solid rgb(175, 175, 175);
        &::placeholder{
            color: rgba(119, 119, 119, 0.30);
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;    
        }
    }

    button{
        display: flex;
        width: 400px;
        padding: 15px 172px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 7px;
        background: #FA913C;
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border:none;
    }
}