.input-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #afafaf;
    border-radius: 7px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    max-height: 44px;

    input {
        width: 100%;
        border: none;
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        font-weight: 600;
        padding: 8px;
        color: #333;

        &::placeholder {
            color: rgba(119, 119, 119, 0.7);
        }

        &:focus {
            outline: none;
            border: none;
        }
    }
}

