.profile {
    display: flex;

    .sideBarContainer {
      width: 60px;
      margin: 2% 3% 3% 3%;
      border-radius: 20px;
      box-shadow: 1px 2px 7px 2px #888888;
      background-color: #f5f5f5;
    }

    .sidebarContent {
      margin-top: 2%;
      flex: 1;
      margin-bottom: 70px;
    }
  }

  @media (max-width: 768px) {
    .profile {
      flex-direction: column;

      .sideBarContainer {
        width: 100%;
        margin: 0;
        box-shadow: none;
        padding: 10px 0;
      }

      .sidebarContent {
        margin-top: 0;
        padding: 0 10px;
      }
    }
  }
