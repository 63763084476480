.formContainer1{
    display:flex;
    padding-top: 4%;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    border-top: 1px solid #f5f5f5;
    
    .formWrapper1{
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        @media (max-width: 768px) {
            width: 90%;
        }

        .title{
            font-size: 28px;
            font-style: normal;
            line-height: normal;
        }
        .errorMessage{
            a{
                color: #FA913C;
            }
        }
        .display-none{
            display: none;
        }
        
        form{
            div {position: relative;outline: none;
                margin-bottom: 40px;}
            .email,
            .password{
            input{
                width: 440px;
                padding: 15px 15px 15px 50px;
                align-items: center;
                background: #f8f8f8;
                border: 1px solid #ececec;
                border-radius: 3px;
                outline: none;
                transition:all 0.1s linear;
                &::placeholder{
                    color: rgba(119, 119, 119, 0.30);
                    font-size: 15px;
                    line-height: normal;
                }
                &:focus{
                    background: #fff;
                }
                @media (max-width: 768px) {
                    width:100%;
                }
            }
            img{
                width: 26px;
                height: 26px;
                position: absolute;
                top: 14px;
                left: 14px;
                opacity: 0.5;
            }
            .eyeIcon{
                cursor: pointer;
                right: 13px;
                left: auto;
            }
            }
            .buttons{
                width: 100%;
                margin-bottom: 40px;
                button{
                    width: 140px;
                    padding: 13px;
                    align-items: flex-start;
                    border-radius: 3px;
                    background: #78bf38;
                    border: 1px solid #78bf38;
                    color: #FFF;
                    text-align: center;
                    line-height: normal;
                    justify-content: center;
                    transition:all 0.2s linear;
                    &:hover{
                        background: #93cc60;
                    }
                }
                a{
                    float: right;
                    padding: 11px;
                    color: #78BF38;
                    border: 1px solid #78BF38;
                    border-radius: 3px;
                    transition:all 0.2s linear;
                    &:hover{
                        background: #93cc60;
                        color: #FFF;
                    }

                }
                span{
                    color: rgba(26, 26, 26, 0.50);
                    font-size: 16px;
                    .link{
                        color: #78BF38;
                        text-decoration: none; 
                    }
                }

            }
              
            @media (max-width: 768px) {
                width: 100%;
                .buttons{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    button{
                        margin-bottom: 20px;
                    }
                }
            } 
        }
    
    }   
    @media (max-width: 768px) {
        padding: 0;
        border: 0;
    } 
}