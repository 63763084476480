.menu{
    height: 50px;
    margin-left: auto;
    margin-right: auto;


    .listProducts{
        .nav{
        list-style: none;
        display: flex;
        justify-content: space-between;
        
        .navItem{
            flex-grow: 1;
            text-align: center;
            &:hover .navSubmenuWrapper{
                opacity: 1;
                visibility: visible;                             
            }
            &.catalog{
                background-color: #78bf38;
                border-radius: 5px 5px 0 0;
                transition: all 0.3s linear;
                text-align: left;
                &:hover{
                    background-color: #93cc60;
                }
                .navLink{
                    color: #fff;
                    padding-left:20px;
                    &:hover{
                        color: #fff;
                    }
                    img{
                        width: 30px;
                        height: 30px;
                    }
                }
                }
            
            .navLink{
                color: #444;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 50px;
                text-decoration: none;
                padding: 14px;
                transition: all 0.3s linear;
                &:hover{
                    color: #93cc60;
                }
            }

            .navSubmenuWrapper{
                position: absolute;
                padding-left: 0; 
                opacity: 0;
                visibility: hidden;
                z-index: 100;
                transition: all 0.3s linear;

                .navSubmenu{      
                    box-shadow: 0 0 4px rgba(0,0,0,.1);
                    display: flex;
                    background-color: white;
                    flex-wrap: wrap;
                    padding: 20px;

                    .item{
                        margin: 10px 10px 10px 10px;
                        display: flex;
                        width: 20%;
                        flex-wrap: wrap;
                        flex-direction: column;

                        .parentTitle{
                            font-size: 18px;
                            
                        }
                        .childItem{
                            color: #333;
                            font-size: 14px;
                            text-decoration: none;
                        }
                    }
                }
            }  
        }    
     }
    }
}