.orders {
  ul {
    list-style-type: none;
    padding: 0; /* Убираем стандартные отступы для списка */
  }

  .title {
    display: flex;
    justify-content: center;
    color: #1a1a1a;
    text-align: center;
    font-family: 'Stanki05', sans-serif; /* Меняем на шрифт Stanki05 */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
  }

  .infoBox {
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 15px;
    align-items: flex-start;
    gap: 15px;
    border-radius: 7px;
    background: #fff;
    box-shadow: 1px 2px 5px 3px #888888;
    margin-bottom: 10px;

    .name {
      color: #1a1a1a;
      font-family: 'Stanki05', sans-serif; /* Меняем на шрифт Stanki05 */
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .index {
      .inn {
        color: #504f4f;
        font-family: 'Stanki05', sans-serif; /* Меняем на шрифт Stanki05 */
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .code {
        color: #504f4f;
        font-family: 'Stanki05', sans-serif; /* Меняем на шрифт Stanki05 */
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .adress {
      color: #504f4f;
      font-family: 'Stanki05', sans-serif; /* Меняем на шрифт Stanki05 */
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .add-profile-btn {
    display: block;
    width: 180px;
    height: 42px;
    border-radius: 5px;
    background-color: #f8f8f8;
    color: #78bf38;
    text-align: center;
    letter-spacing: .8px;
    line-height: 14px;
    border: 1px solid #78bf38;
    padding: 11px 15px 10px;
    font-size: 10px;
    font-family: 'Stanki05', sans-serif; /* Меняем на шрифт Stanki05 */
    text-transform: uppercase;
    transition: all .3s;
    margin: 20px auto;
  
    &:hover {
        background-color: #93cc60;
        color: #f8f8f8;
    }
  }

  /* Мобильная версия для экранов с шириной меньше 768px */
  @media (max-width: 768px) {
    .orders {
      .title {
        font-size: 18px;
        margin-bottom: 15px;
      }

      .infoBox {
        width: 100%;
        padding: 10px;
        gap: 10px;
        margin-bottom: 8px;
        box-shadow: 1px 1px 3px 2px #888888;

        .name {
          font-size: 18px;
        }

        .index {
          .inn,
          .code {
            font-size: 14px;
          }
        }

        .adress {
          font-size: 14px;
        }
      }
    }
  }

  /* Дополнительно для более маленьких экранов (до 480px) */
  @media (max-width: 480px) {
    .orders {
      .infoBox {
        padding: 8px;
        box-shadow: 1px 1px 2px 1px #888888;
      }
    }
  }
}
