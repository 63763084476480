.contacts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.contact_info {
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    div{
        width: 50%;
    }
    ul{
        margin: 0;
        padding: 0;
        li{
            list-style: none;
            &.title{
                font-size: 14px;
                color: #777;
                text-transform: uppercase;
            }
        }
    }
}

.map {
    width: 50%;
}