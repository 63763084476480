.footerbar{
    padding: 60px 0;
    background-color: rgb(56 58 60 / 80%);
    color:#fff;
    .footer-title {
        h5{
        font-size: 18px;
        line-height: 24px;
        text-transform: capitalize;
        font-weight: 500;
        }
    }
    ul{
        color:#fff;
        margin: 0;
        padding: 0 0 0 10px;
        li {
            list-style: none;
            a{
              color:#fff;
              &.ins{
                color: #f77c18;
                img{
                    max-width: 250px;
                }
              }
              &.footer__num_bold{
                font-size: 24px;
              }
            }
            &.footer__whatsapp {
                padding-top: 10px;
            }
        }
    }
    
          img{
              max-width: 250px;
          }
}