.profiles {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      h1 {
        font-size: 1.5rem;
        font-weight: 500;
      }
  
      .buttons {
        display: flex;
        gap: 5px;
  
        .editBtn,
        .exitBtn {
          display: flex;
          align-items: center;
          border: none;
          border-radius: 20px;
          cursor: pointer;
          color: white;
          padding: 8px 12px;
          font-size: 0.875rem;
  
          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
        }
  
        .editBtn {
          background-color: #fa913c;
        }
  
        .exitBtn {
          background-color: #f57676;
        }
      }
    }
  
    .info {
      display: flex;
      flex-direction: column;
      gap: 10px;
  
      .profile-item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 10px;
        background-color: #f9f9f9;
  
        img {
          width: 24px;
          height: 24px;
        }
  
        .text {
          display: flex;
          flex-direction: column;
  
          .title {
            font-weight: 600;
            font-size: 0.875rem;
          }
  
          .value {
            font-weight: 400;
            font-size: 0.875rem;
          }
        }
      }
    }
  
    .chart {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
  
    .history {
      h2 {
        font-size: 1.25rem;
        margin-bottom: 10px;
      }
  
      ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
  
        li {
          .history-item {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 8px;
            border: 1px solid #ddd;
            border-radius: 10px;
            background-color: #f9f9f9;
  
            p {
              margin: 0;
            }
  
            time {
              font-size: 0.75rem;
              color: #555;
            }
          }
        }
      }
    }
  }
  
  @media (min-width: 768px) {
    .profiles {
      padding: 40px;
      gap: 40px;
  
      .header {
        h1 {
          font-size: 2rem;
        }
  
        .buttons {
          gap: 20px;
  
          .editBtn,
          .exitBtn {
            padding: 10px 20px;
            font-size: 1.25rem;
  
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
  
      .info {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
  
        .profile-item {
          flex: 1 1 calc(50% - 20px);
        }
      }
  
      .history {
        h2 {
          font-size: 2rem;
        }
  
        ul {
          flex-direction: row;
          flex-wrap: wrap;
          gap: 20px;
  
          li {
            flex: 1 1 calc(50% - 20px);
          }
        }
      }
    }
  }
  