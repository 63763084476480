.formContainer2{
    background-position: center center;
    display:flex;
    padding-top: 4%;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;border-top: 1px solid #f5f5f5;
    
    .formWrapper2{
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;

        .title{
            font-size: 28px;
            font-style: normal;
            line-height: normal;
        }

        .errorText {
            color: #FA913C;
        }
        
        form{
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            margin-inline: 40px;
            div{
                position: relative;
                margin-bottom: 40px
            }
    
            .email{
            input{
                width: 440px;
                padding: 15px 15px 15px 50px;
                align-items: center;
                background: #f8f8f8;
                border: 1px solid #ececec;
                border-radius: 3px;
                outline: none;
                transition:all 0.1s linear;
                &::placeholder{
                    color: rgba(119, 119, 119, 0.30);
                    font-size: 15px;
                    line-height: normal;
                }
                &:focus{
                    background: #fff;
                }
            }
            
            img{
                width: 26px;
                height: 26px;
                position: absolute;
                top: 14px;
                left: 14px;
                opacity: 0.5;
            }
            }
            button{
                width: 70%;
                margin: 0 auto;
                padding: 13px;
                align-items: flex-start;
                border-radius: 3px;
                background: #FA913C;
                border: 1px solid #FA913C;
                color: #FFF;
                text-align: center;
                font-size: 16px;
                line-height: normal;
                justify-content: center;
                transition:all 0.2s linear;
                &:hover{
                    background: #f77c18;
                }
            }

        }
    }
}