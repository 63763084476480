.search{
    display: flex;
    align-items: flex-end;
    width: 50%;
    position: relative;
    input{
        outline: none;
        width: 100%;
        padding: 0 44px 0 16px;
        height: 42px;
        font-size: 13px;
        line-height: 40px;
        background: #fafafa;
        border: 1px solid #ececec;
        border-radius: 3px;
        &::placeholder{
            color: rgb(175, 175, 175);
        }
    }
    button{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        padding: 0 16px;
        border: none;
        background: 0 0;
        font-size: 0;
        &:hover {
            opacity: 0.6;
            transition: all .15s linear;
        }

        img{
            width: 24px;
            height: 24px;
        }
    }
}
@media (max-width: 768px) {
.header-wrapper .content_block .search {
    width: 55%;
}
.header-wrapper .content_block .search input{
    height: 38px;
    padding: 0 40px 0 10px;
}
.header-wrapper .content_block .search button{
    padding: 0 12px;
}
.header-wrapper .content_block .search button img{
    width: 18px;
}

}