.top-wrapper{
    border-bottom: 1px solid #f5f5f5;
    .content_block{
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        color: #777;
        font-size:14px;

        .phone{
            color: #222;
            font-weight: 700;
            font-size:16px;
        }
        .adress{
        }
        .auth{
            a{
                color:#777;
                &:hover{
                    color:#93cc60
                }
                img{
                    width: 24px;
                    height: 24px;
                }
            }

        }
    }

}
.header-wrapper{
.content_block{
    position: relative;
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: space-between;
    .logo{
        img {
            width: 240px;
            height: 40px;
        }
    }
    .top-description{
        color: #999;
        font-size: 13px;
    }
    

    .icons{
        display: flex;
        align-items: center;
        cursor: pointer;

        .cart{
            position: relative;
            padding: 0;
            a {
                path {
                    transition: 0.05s all linear;
                }
                &:hover {
                    path {
                    stroke: #93cc60 !important;
                    fill: #93cc60 !important;
                    }
                }
            }
            span{
                width: 16px;
                height: 16px;
                border-radius: 50%;
                position: absolute;
                top: -10px;
                right: -10px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                
            }
            &:hover {
                span{
                    color:#93cc60;
                }
            }
        }

        .iconBucket{
            width: 30px;
            height: 30px; 
            color: red;
        }

        .icon{
            width: 30px;
            height: 30px;
        
        }
    }
}
}
@media (max-width: 768px) {
.top-wrapper,.header-wrapper{
        background-color: #fff;
        left: 0;
        position: fixed;
        width: 100%;
        z-index: 10002;
    }
    .top-wrapper{top: 0;}
    .header-wrapper{
        top: 35px;
        border-bottom: 1px solid #ccc;
        }
    .top-wrapper .content_block,
    .header-wrapper .content_block{
        padding: 5px 15px;
    }
    .header-wrapper .content_block{
        /* flex-direction: column; */
        height: 60px;
    }
    .header-wrapper .content_block .logo img{
        width: 140px;
        height: auto;
    }
    .top-wrapper .content_block .auth,
    .header-wrapper .content_block .top-description,
    .header-wrapper .content_block .icons,
    .menu,
    .carousel .carousel-inner .carousel-item .carousel-caption,
    .footerbar{
        display: none;
    }
}
    