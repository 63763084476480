.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Reduced gap for better fitting on mobile */
  padding: 10px;
  background-color: #f5f5f5;

  /* Убираем закругление самого сайдбара */
  border-radius: 0 !important; /* Добавляем !important для принудительного удаления закругления */

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px; /* Adjusted width for better mobile fit */
    padding: 8px; /* Reduced padding */
    cursor: pointer;
    transition: background-color 0.3s ease;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      color: black;
      font-size: 10px; /* Smaller font size for labels */
    }

    svg {
      font-size: 20px; /* Adjusted icon size */
      margin-bottom: 5px;
    }

    &:hover {
      background-color: #78bf38; /* Изменяем цвет фона при наведении на зеленый */
      color: white;

      a {
        color: white;
      }
    }

    /* Стиль активного элемента */
    &.active {
      background-color: #78bf38; /* Зеленый фон для активного элемента */

      a {
        color: white; /* Текст активного элемента белый на зеленом фоне */
      }
    }
  }
}

@media (max-width: 768px) {
  .sidebar {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding: 10px 0;

    .item {
      width: auto;

      a {
        font-size: 9px; /* Smaller font size for mobile */
      }

      svg {
        font-size: 18px; /* Adjusted icon size for mobile */
      }
    }
  }
}
