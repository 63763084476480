@font-face {
    font-family: "Stanki05";
    src: url('./utils/fonts/NeusaNextPro-WideRegular.woff2') format('woff2'),
         url('./utils/fonts/NeusaNextPro-WideRegular.woff') format('woff'),
         url('./utils/fonts/NeusaNextPro-WideRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Stanki05";
    src: url('./utils/fonts/NeusaNextPro-WideMedium.woff2') format('woff2'),
         url('./utils/fonts/NeusaNextPro-WideMedium.woff') format('woff'),
         url('./utils/fonts/NeusaNextPro-WideMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Stanki05";
    src: url('./utils/fonts/NeusaNextPro-WideBold.woff2') format('woff2'),
         url('./utils/fonts/NeusaNextPro-WideBold.woff') format('woff'),
         url('./utils/fonts/NeusaNextPro-WideBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

body, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .popup-window, body div.bx-yandex-map, .fancybox-title {
    font-family: 'Stanki05', sans-serif;
}

body {
    color: #444;
}

a {
    color: #78bf38;
    text-decoration: none;
    transition: all 0.15s linear;
    
    &:hover {
        color: #93cc60;
    }
}

h2 {
    font-size: 24px;
}

.content_block {
    max-width: 1408px;
    margin: 0 auto;
}

.content {
    border-top: 1px solid #f5f5f5;

    .content_block {
        padding: 30px 0 90px;
    }
}

.page_title {
    display: flex;
    padding: 0 0 30px;

    h1 {
        font-size: 28px;
        margin: 0;
        padding: 0;
    }
}

.page_content {
    &.center {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .empty-cart-image {
        width: 100px;
        padding-bottom: 40px;
    }

    .empty-cart-message {
        font-size: 24px;
    }

    .empty-cart-link {
        margin-bottom: 90px;
    }
}

@media (max-width: 768px) {
    .content {
        top: 95px;
        position: relative;

        .content_block {
            padding: 15px;
            max-width: none;
        }
    }
}
