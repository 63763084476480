.edit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin-inline: auto;
    max-width: 400px;

    .title {
        color: #1A1A1A;
        text-align: center;
        font-family: Montserrat, sans-serif;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .input-group {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .input-wrapper {
        display: flex;
        align-items: center;
        border: 1px solid #afafaf;
        border-radius: 7px;
        padding: 10px;
        background-color: #fff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1); /* Легкая тень */

        img.icon {
            width: 24px;
            height: 24px;
            margin-right: 10px; /* Отступ для иконки */
        }

        input {
            width: 100%;
            border: none;
            font-family: Montserrat, sans-serif;
            font-size: 16px;
            font-weight: 600;
            padding: 8px;
            color: #333;

            &::placeholder {
                color: rgba(119, 119, 119, 0.7);
            }

            &:focus {
                outline: none;
                border: none;
            }
        }
    }

    label {
        font-family: Montserrat, sans-serif;
        font-weight: bold;
        margin-bottom: 5px;
        color: #1A1A1A;
    }

    button {
        width: 180px;
        height: 42px;
        border-radius: 5px;
        background-color: #f8f8f8;
        color: #78bf38;
        text-align: center;
        letter-spacing: .8px;
        line-height: 14px;
        border: 1px solid #78bf38;
        padding: 11px 15px 10px;
        font-size: 10px;
        text-transform: uppercase;
        transition: all .3s;
        margin: 0 auto; /* Центрируем кнопку горизонтально */
    
        &:hover {
            background-color: #93cc60;
            color: #f8f8f8;
        }
    }

    .error {
        color: red;
    }
    
}
